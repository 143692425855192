import React from 'react';
import { graphql,Link } from 'gatsby';
import Markdown from '../components/views/Markdown';
import styled from 'styled-components';
import TwoColLayout from '../components/layout/TwoColLayout';
import { FaHome,FaFolder,FaRegCalendarAlt,FaTag } from "react-icons/fa";
import { StaticImage, GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { Container, Row, Col } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import Gitalk from 'gatsby-plugin-gitalk';
import '@suziwen/gitalk/dist/gitalk.css';
import moment from 'moment';
import { IssueQuery } from '../../graphql-types';
import ShareButtons from '../components/parts/ShareButton';
import SideBar from '../components/views/SideBar';
import { kebabCase } from 'lodash/string';

type Props = {
  data : IssueQuery
}


export default function IssuePage(props: Props) {
  const { mdx:issue } = props.data;
  const { frontmatter,excerpt } = props.data.mdx;
  const { title,slug,icon,category,date,tags } = props.data.mdx.frontmatter;
  const { title:siteTitle,description:siteDesc } = props.data.site.siteMetadata;
  const thumbnail = props.data.mdx.frontmatter.thumbnail?.childImageSharp.fluid.src;
  const imageUrl = `${props.data.site.siteMetadata.siteUrl}${thumbnail}`;
  const pageUrl = `${props.data.site.siteMetadata.siteUrl}/${slug}`;
  const MainContent = styled.div`
    .pankuzu{ font-size:13px; }
    .pankuzu a{ text-decoration: none; color:#333; }
    .page-header{ background-color: #333; padding: 30px; }
    .page-header .gatsby-image-wrapper{ border-radius: 5px; }
    .page-header h1{ font-size: 26px; font-weight: bold; color: white; }
    .post-meta a{ text-decoration: none; color:#ddd; }
    .post-meta svg{ transform: translate(1px,-2px); }
    .gt-container{ margin-bottom: 40px; }
    .date,.tag{ font-size:13px; color:#ddd; }
    .markdown-body pre{ border:1px solid black; }
    #comment{
      padding: 15px;
      border-left: 8px solid black;
      border-bottom: 1px solid black;
      font-size: 26px;
      counter-increment: h2 1;
      counter-reset: h3 0;
      margin: 20px 0 !important;
      font-weight: bold;
    }
  `;
  let gitalkConfig = { id: issue.id, title: title, }
  return (
    <TwoColLayout
      data={props.data}
      main={
        <MainContent>
          <Helmet>
            <html lang="ja" />
            <title>[問題解決]{title} | TERAKOYA</title>
            <meta name="description" content={siteDesc} />
            <meta property="og:url" content={pageUrl} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={`[問題解決]${title}|${siteTitle}`} />
            <meta property="og:description" content={excerpt.replace(`\n`,"")} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:width" content="1200" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image" content={imageUrl} />
            <meta name="twitter:title" content={`[問題解決]${title}|${siteTitle}`} />
            <meta name="twitter:description" content={excerpt.replace(`\n`,"")} />
          </Helmet>
          <p className='pankuzu'>
            <Link to="/"><FaHome /> HOME</Link> &gt; 
            <Link to={`/issues`}> <FaFolder /> 問題解決一覧 </Link> &gt; 
            <span> {title}</span>
          </p>
          <div className='page-header'>
            <Row>
              <Col md={2} xs={12}>
              {getImage(icon as ImageDataLike) ? (
                <GatsbyImage image={getImage(icon as ImageDataLike) as IGatsbyImageData} alt={title}
                />
              ) : (
                <StaticImage alt="no image" src="../../images/no-image.png"
                />
              )}
              </Col>
              <Col md={10} xs={12}>
                <h1>{title}</h1>
                <div className='post-meta'>
                  <span className='date'><FaRegCalendarAlt /> {moment(date).format(`YYYY年M月D日 H:mm`)} </span>
                  <br />
                  <span className='tag'>
                    <FaTag />
                    { tags.map((tag:string) => (
                      <Link key={tag} to={`/tag/${kebabCase(tag)}`}><span> { tag } </span></Link>
                    ))}
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <ShareButtons url={pageUrl} title={title}></ShareButtons>
          <Markdown>{issue.body}</Markdown>
          <h2 id="comment">コメント</h2>
          <p>この記事に関する質問やご意見・ご感想がありましたら、下記よりコメントください。<br/>Githubアカウントでログインしていれば誰でも投稿できます。</p>
          <Gitalk options={gitalkConfig}/>        
        </MainContent>
      }
      side={
        <div>
          <SideBar data={props.data}/>
        </div>
      }
    />
  );
}

export const query = graphql`
  query Issue($id: String) {
    site {
      siteMetadata {
        title
        description
        siteUrl
        headerLinks{
          label
          url
        }
      }
    }
    mdx(id: { eq: $id }) {
      frontmatter {
        slug
        date
        title
        category
        posttype
        thumbnail {
          childImageSharp {
            gatsbyImageData
            fluid {
              src
            }
          }
        }
        icon {
          childImageSharp {
            gatsbyImageData
          }
        }
        tags
      }
      id
      excerpt(truncate: true)
      body
    }
  }
`;
